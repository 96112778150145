var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, height: _vm.height }
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", width: "60", label: "序号" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "userName",
              label: "用户",
              width: 100 * this.$store.state.vehicle.screenCoefficient,
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "duration",
              label: "在岗时长(分钟)",
              formatter: _vm.formateTime,
              width: 110 * this.$store.state.vehicle.screenCoefficient,
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "companyName",
              label: "道路运输企业",
              formatter: _vm.formatNull,
              width: 240 * this.$store.state.vehicle.screenCoefficient,
              "show-overflow-tooltip": true
            }
          }),
          _c(
            "el-table-column",
            { attrs: { label: "抽查内容(车辆数)" } },
            [
              _c("el-table-column", {
                attrs: {
                  label: "查看定位信息",
                  prop: "localCount",
                  width: 120 * this.$store.state.vehicle.screenCoefficient
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "查看历史轨迹",
                  prop: "travelCount",
                  width: 120 * this.$store.state.vehicle.screenCoefficient
                }
              }),
              _c("el-table-column", {
                attrs: { label: "查看视频", prop: "videoCount" }
              }),
              _c("el-table-column", {
                attrs: { label: "违章处理", prop: "illegalCount" }
              })
            ],
            1
          ),
          _c("el-table-column", {
            attrs: { prop: "vehicleCount", label: "车辆总数" }
          }),
          _c("el-table-column", { attrs: { prop: "allCount", label: "合计" } }),
          _c(
            "el-table-column",
            { attrs: { label: "抽查内容(百分比)" } },
            [
              _c("el-table-column", {
                attrs: {
                  label: "查看定位信息",
                  prop: "localPercentage",
                  formatter: _vm.formatRate,
                  width: 120 * this.$store.state.vehicle.screenCoefficient
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "查看历史轨迹",
                  prop: "travelPercentage",
                  formatter: _vm.formatRate,
                  width: 120 * this.$store.state.vehicle.screenCoefficient
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "查看视频",
                  prop: "videoPercentage",
                  formatter: _vm.formatRate
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "违章处理",
                  formatter: _vm.formatRate,
                  prop: "illegalPercentage"
                }
              })
            ],
            1
          ),
          _c("el-table-column", {
            attrs: {
              prop: "percentageCount",
              label: "合计",
              formatter: _vm.formatRate
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              "page-size": _vm.pageObj.pageSize,
              "current-page": _vm.pageObj.currentPage
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }