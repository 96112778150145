<template>
  <div>
    <el-table :data="tableData" style="width: 100%" :height="height">
      <el-table-column type="index" width="60" label="序号"></el-table-column>
      <el-table-column
        prop="userName"
        label="用户"
        :width="100 * this.$store.state.vehicle.screenCoefficient"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="duration"
        label="在岗时长(分钟)"
        :formatter="formateTime"
        :width="110 * this.$store.state.vehicle.screenCoefficient"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="companyName"
        label="道路运输企业"
        :formatter="formatNull"
        :width="240 * this.$store.state.vehicle.screenCoefficient"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column label="抽查内容(车辆数)">
        <el-table-column
          label="查看定位信息"
          prop="localCount"
          :width="120 * this.$store.state.vehicle.screenCoefficient"
        ></el-table-column>
        <el-table-column
          label="查看历史轨迹"
          prop="travelCount"
          :width="120 * this.$store.state.vehicle.screenCoefficient"
        ></el-table-column>
        <el-table-column label="查看视频" prop="videoCount"> </el-table-column>
        <el-table-column label="违章处理" prop="illegalCount"></el-table-column>
      </el-table-column>
      <el-table-column prop="vehicleCount" label="车辆总数"></el-table-column>
      <el-table-column prop="allCount" label="合计"></el-table-column>
      <el-table-column label="抽查内容(百分比)">
        <el-table-column
          label="查看定位信息"
          prop="localPercentage"
          :formatter="formatRate"
          :width="120 * this.$store.state.vehicle.screenCoefficient"
        ></el-table-column>
        <el-table-column
          label="查看历史轨迹"
          prop="travelPercentage"
          :formatter="formatRate"
          :width="120 * this.$store.state.vehicle.screenCoefficient"
        ></el-table-column>
        <el-table-column
          label="查看视频"
          prop="videoPercentage"
          :formatter="formatRate"
        >
        </el-table-column>
        <el-table-column
          label="违章处理"
          :formatter="formatRate"
          prop="illegalPercentage"
        ></el-table-column>
      </el-table-column>
      <el-table-column
        prop="percentageCount"
        label="合计"
        :formatter="formatRate"
      ></el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="pageObj.pageSize"
        :current-page="pageObj.currentPage"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { YHTools } from '@/assets/js/Tools.js'

export default {
  props: {
    height: {
      type: Number
    },
    tableData: {
      type: Array
    },
    total: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      pageObj: {
        currentPage: 1,
        pageSize: 10
      }
    }
  },
  methods: {
    /**数据为空默认显示- */
    formatNull(row, column, cellValue) {
      return cellValue == null || cellValue == '' ? '-' : cellValue
    },
    /**查询时,分页参数还原 */
    reset() {
      this.pageObj.pageSize = 10
      this.pageObj.currentPage = 1
    },
    /** 分页参数切换*/
    handleSizeChange(val) {
      this.pageObj.pageSize = val
      this.pageObj.currentPage = 1
      this.$emit('sendInfo', this.pageObj)
    },
    /**分页参数切换 */
    handleCurrentChange(val) {
      this.pageObj.currentPage = val
      this.$emit('sendInfo', this.pageObj)
    },
    /** 时长 */
    formateTime(cellrow, column, cellValue) {
      return cellValue == null || cellValue === '' || cellValue === 0
        ? '-'
        : YHTools.FormatTimeBySeconds(cellValue, true)
    },
    /** 数据% */
    formatRate(row, column, cellValue) {
      return cellValue == null || cellValue === ''
        ? '-'
        : `${this.formatNumber(cellValue, 100)}%`
    },
    /** 整数||小数 */
    formatNumber(cellValue, number) {
      number = number || 1
      return parseInt(cellValue * number) < parseFloat(cellValue * number)
        ? (cellValue * number).toFixed(2)
        : cellValue * number
    }
  }
}
</script>

<style>
</style>
