var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "checkVehDetail" },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, height: _vm.height }
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", label: "序号", width: "50" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "cph",
              label: "车牌号",
              width: 100 * _vm.$store.state.vehicle.screenCoefficient,
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "车牌颜色",
              width: 80 * _vm.$store.state.vehicle.screenCoefficient,
              "show-overflow-tooltip": true
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.typeObj[Number(scope.row.licensePlateColor)]
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "车辆类型",
              width: 130 * _vm.$store.state.vehicle.screenCoefficient,
              "show-overflow-tooltip": true
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.modelObj[Number(scope.row.vehicleType)]) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "companyName",
              label: "所属道路运输企业",
              width: 240 * this.$store.state.vehicle.screenCoefficient,
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "gpsTime",
              label: "定位时间",
              formatter: _vm.formatNull,
              width: 170 * _vm.$store.state.vehicle.screenCoefficient,
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "velocity",
              label: "速度",
              formatter: _vm.formatNull
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "limitSpeed", label: "限速" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "position",
              label: "位置",
              width: 220 * _vm.$store.state.vehicle.screenCoefficient,
              "show-overflow-tooltip": true,
              formatter: _vm.formatNull
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "spotName",
              label: "抽查内容",
              width: 140 * _vm.$store.state.vehicle.screenCoefficient,
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "userName",
              label: "抽查人",
              width: 80 * _vm.$store.state.vehicle.screenCoefficient,
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "createTime",
              label: "抽查时间",
              width: 170 * _vm.$store.state.vehicle.screenCoefficient,
              "show-overflow-tooltip": true
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              "page-size": _vm.pageObj.pageSize,
              "current-page": _vm.pageObj.currentPage
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }